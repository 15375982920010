<template>
  <div class="page">
    <div class="page-title">糟糕！</div>
    <div class="page-center">
      <img src="@/assets/images/others/404.png">
    </div>
    <div class="page-desc">页面走丢了~</div>
  </div>
</template>

<script>
export default {
  name: "404Page"
}
</script>

<style scoped>
.page{
  display: flex;
  flex-direction: column;
  text-align: center;
  width:100%;
  height: 100%;
  padding:20px;
  box-sizing: border-box;
}
.page-title{
  width: 100%;
  font-size:32px;
  font-weight: bold;
  padding:10px;
  box-sizing: border-box;
}
.page-desc{
  width: 100%;
  font-size:16px;
  padding:10px;
  box-sizing: border-box;
}
.page-center{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.page-center img{
  display: block;
  width: 80%;
  height:auto;
  max-width:654PX;
}
</style>
